import React from 'react';

const BildeBoks = ({images}) => {
    switch (images.length) {
        case 0:
            return null
        case 1:
            return <img className='bildeboks1' alt="" src={images[0]} draggable="false"/>
        case 2:
            return <div className='bildeboks2'>
                    <img alt="" src={images[0]} draggable="false"/>
                    <img alt="" src={images[1]} draggable="false"/>
                </div>
        case 3:
            return <div className='bildeboks3'>
                    <img alt="" src={images[0]} draggable="false"/>
                    <img alt="" src={images[1]} draggable="false"/>
                    <img alt="" src={images[2]} draggable="false"/>
                </div>
        default:
            return <div className='bildeboksx'>
                    {images.map((image, i) =>{
                return <img alt="" src={image} key={i} draggable="false"/>})}
                </div>
    }
}

export default BildeBoks;