import React from "react";
import xDark from '../assets/x-dark.svg';

const Modal = ({ setIsOpen, children }) => {
  return <>
  <div className="morkBakgrunn" onClick={() => setIsOpen(false)} />
  <div className="centered">
    <div className="modal">
        <button className="closeBtn" onClick={() => setIsOpen(false)}>
            <img className="dark" src={xDark} alt=""/>
        </button>
            <div className="modalContent">
                {children}
            </div>
        </div>
  </div>
</>
};

export default Modal;