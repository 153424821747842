import React from 'react';
import './styles/main.less';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import Welcome from "./Welcome";

function App() {
    return (
        <div className="App">
            <Router>
                <main>
                    <Route exact path="/" component={Welcome}/>
                </main>
            </Router>
        </div>
    );
}

export default App;
