import React from "react";
import Oppsett from "./OppsettPC";
import ArrowLight from "./assets/point-down-light.svg";
import ArrowDark from "./assets/point-down-dark.svg";
import logo_light from "./Bekk-Hovedlogo-lys.svg";
import "./assets/fonts/GT-America-Standard-Regular.woff";

const Welcome = () => {
  const scroll = () => {
    document.querySelector("#oppsett").scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <>
      <article className="welcome">
        <img alt="Bekk logo" id="logo" src={logo_light} />
        <h1 className="welcome__header">Velkommen til Bekk!</h1>
        <h2>Nedenfor finner du en guide til oppsett av laptopen din.</h2>
        <button onClick={scroll} className="scroll-button button-light">
          <p style={{ whiteSpace: "nowrap" }}>KOM I GANG</p>
          <span className="screen-reader-text">Scroll ned</span>
          <img className="light" src={ArrowLight} alt="" />
          <img className="dark" src={ArrowDark} alt="" />
        </button>
      </article>
      <Oppsett />
    </>
  );
};

export default Welcome;
