import React from "react";
import leftDark from "../assets/point-left-dark.svg";
import rightDark from "../assets/point-right-dark.svg";

const NavigasjonsKnapper = ({
  limit,
  current,
  backward,
  forward,
  zeroAction,
  endAction,
}) => {
  return (
    <div className="modal__navigation-button-container">
      {current === 0 && zeroAction !== null && (
        <button className="modal__navigation-button" onClick={zeroAction}>
          <img className="dark" src={leftDark} alt="" />
          <p className="buttonText">FORRIGE</p>
        </button>
      )}
      {current > 0 && (
        <button className="modal__navigation-button" onClick={backward}>
          <img className="dark" src={leftDark} alt="" />
          <p className="buttonText">FORRIGE</p>
        </button>
      )}
      {limit - current > 1 && (
        <button className="modal__navigation-button" onClick={forward}>
          <p className="buttonText">NESTE</p>
          <img className="dark" src={rightDark} alt="" />
        </button>
      )}
      {limit - current === 1 && (
        <button className="modal__navigation-button" onClick={endAction}>
          <p className="buttonText">LUKK VINDUET</p>
        </button>
      )}
    </div>
  );
};

export default NavigasjonsKnapper;
