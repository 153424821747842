import React, { useState } from "react";
import img1 from "./assets/images/img1.png";
import img2 from "./assets/images/img2.png";
import img3 from "./assets/images/img3.png";
import img4 from "./assets/images/img4.png";
import img5 from "./assets/images/img5.png";
import img6 from "./assets/images/img6.png";
import img7 from "./assets/images/img7.png";
import img8 from "./assets/images/img8.png";
import img9 from "./assets/images/img9.png";
import img10 from "./assets/images/img10.png";
import img11 from "./assets/images/img11.png";
import img12 from "./assets/images/img12.png";
import img13 from "./assets/images/img13.png";
import img14 from "./assets/images/img14.png";
import img15 from "./assets/images/img15.png";
import img16 from "./assets/images/img16.png";
import img17 from "./assets/images/img17.png";
import img18 from "./assets/images/img18.png";
import img19 from "./assets/images/img19.png";
import img20 from "./assets/images/img20.png";
import img24 from "./assets/images/img24.png";
import img25 from "./assets/images/img25.png";
import img26 from "./assets/images/img26.png";
import img27 from "./assets/images/img27.png";
import img28 from "./assets/images/img28.png";
import img29 from "./assets/images/img29.png";
import img30 from "./assets/images/img30.png";
import img31 from "./assets/images/img31.png";
import BildeBoks from "./components/BildeBoks";
import NavigasjonsKnapper from "./components/Navigasjonsknapper";
import "./bytt-passord.less";

const macPages = [
  {
    bilde: [],
    beskrivelse: (
      <div className="modal__description">
        <h1>Logg inn på laptopen</h1>
        <p>
          Brukernavnet er fornavnet ditt. <br />
          <br />
        </p>

        <p>
          Hvis du har flere fornavn, er det første fornavn som gjelder. Hvis
          fornavnet ditt har bindestrek, er brukernavnet begge fornavn i ett
          ord, uten bindestrek (f.eks. Ole-Kristian = olekristian).
          <br />
          <br />
        </p>
        <p>
          Passordet er <b>skuret12W!</b>
        </p>
      </div>
    ),
  },
  {
    bilde: [img1],
    beskrivelse: (
      <div className="modal__description">
        <h1>Åpne og logg inn på "Company Portal"</h1>
      </div>
    ),
  },
  {
    bilde: [img2],
    beskrivelse: (
      <div className="modal__description">
        <h1>Bytt passord del 1</h1>
        <p>Trykk på ikonet med initialer oppe i høyre hjørne, e.g. "OM"</p>
      </div>
    ),
  },
  {
    bilde: [img3],
    beskrivelse: (
      <div className="modal__description">
        <h1>Bytt passord del 2</h1>
        <p>Velg "Change Password".</p>
        <p>
          Hvis det ikke skjer noe når du trykker på change password prøv denne
          lenken: <a href="https://bit.ly/2ChSai7">https://bit.ly/2ChSai7</a>
        </p>
      </div>
    ),
  },
  {
    bilde: [img4],
    beskrivelse: (
      <div className="modal__description">
        <h1>Trykk "Next"</h1>
      </div>
    ),
  },
  {
    bilde: [img5],
    beskrivelse: (
      <div className="modal__description">
        <h1>Microsoft Authenticator del 1</h1>
        <p>
          Last ned Microsoft Authenticator appen på mobilen din og trykk “Next”.
        </p>
      </div>
    ),
  },
  {
    bilde: [img6],
    beskrivelse: (
      <div className="modal__description">
        <h1>Microsoft Authenticator del 2</h1>
        <p>Trykk "Next"</p>
      </div>
    ),
  },
  {
    bilde: [img7],
    beskrivelse: (
      <div className="modal__description">
        <h1>Microsoft Authenticator del 3</h1>
        <p>Åpne appen og trykk på + oppe i høyre hjørne.</p>
      </div>
    ),
  },
  {
    bilde: [img8],
    beskrivelse: (
      <div className="modal__description">
        <h1>Microsoft Authenticator del 4</h1>
        <p>Velg “Jobb- eller skolekonto” og “Scan QR-kode”.</p>
      </div>
    ),
  },
  {
    bilde: [img9],
    beskrivelse: (
      <div className="modal__description">
        <h1>Microsoft Authenticator del 5</h1>
        <p>Scan QR-koden fra laptopen med mobilen din.</p>
      </div>
    ),
  },
  {
    bilde: [img10, img11, img12],
    beskrivelse: (
      <div className="modal__description">
        <h1>Microsoft Authenticator del 6</h1>

        <p>Skriv inn nummeret i appen og svar “Ja”</p>
        <p>Trykk “Next”</p>
      </div>
    ),
  },
  {
    bilde: [img13],
    beskrivelse: (
      <div className="modal__description">
        <h1>Microsoft Authenticator del 7</h1>
        <p>Trykk “Done”</p>
      </div>
    ),
  },
  {
    bilde: [img14],
    beskrivelse: (
      <div className="modal__description">
        <h1>Microsoft Authenticator del 8</h1>

        <p>Skriv inn nummeret fra prompten i Authenticator appen</p>
      </div>
    ),
  },
  {
    bilde: [img15],
    beskrivelse: (
      <div className="modal__description">
        <h1>Microsoft Authenticator del 8</h1>

        <p>Verifiser at du skrev riktig kode og trykk "Ja"</p>
      </div>
    ),
  },
  {
    bilde: [img16],
    beskrivelse: (
      <div className="modal__description">
        <h1>Endre passord</h1>

        <p>Skriv inn passordet "skuret12W!" i "Old password" feltet.</p>

        <p>
          Opprett et nytt, personlig passord. Dette må inneholde minst 16 tegn
          og kan ikke inneholde æ, ø og å. Dette passordet vil være Bekk-passord
          og vil brukes til å logge inn på Bekk sine ressurser og din
          Bekk-laptop.
        </p>

        <p>Trykk "Submit"</p>
      </div>
    ),
  },
  {
    bilde: [img17],
    beskrivelse: (
      <div className="modal__description">
        <h1>Velg "Not now".</h1>
      </div>
    ),
  },
  {
    bilde: [img18],
    beskrivelse: (
      <div className="modal__description">
        <p>
          Steng nettleservinduet og ta deg en fem-minutters pause mens passordet
          ditt blir synkronisert.
        </p>
      </div>
    ),
  },
  {
    bilde: [img19],
    beskrivelse: (
      <div className="modal__description">
        <p>
          La maskinen stå i 5 min. Da vil skjermspareren skru seg på. Etter at
          skjermsparer har skrudd seg på, logg inn med det nye passordet ditt.
          Brukernavnet er fornavnet ditt med små bokstaver (dobbeltnavn er uten
          mellomrom).
        </p>
      </div>
    ),
  },
  {
    bilde: [img20],
    beskrivelse: (
      <div className="modal__description">
        <h1>Passordoppsettet er fullført!</h1>
        <p>
          Se etter en Notification oppe i høyre hjørne. Nå er det nye passordet
          ditt synkronisert med den lokale brukerkontoen på Macen din slik at
          både Macen og Office365 har samme innloggingsinformasjon.
        </p>
      </div>
    ),
  },
];

const pcPages = [
  {
    bilde: [img24],
    beskrivelse: (
      <div className="modal__description">
        <h1>Logg inn</h1>
        <p>
          Passordet er <b>skuret12W!</b>
        </p>
      </div>
    ),
  },
  {
    bilde: [img25],
    beskrivelse: (
      <div className="modal__description">
        <h1>Sett opp Windows Hello</h1>
        <p>
          Velg “Yes, set up” hvis du vil sette opp Face ID eller “Skip for now”
          hvis du ikke ønsker dette.
        </p>
      </div>
    ),
  },
  {
    bilde: [img26],
    beskrivelse: (
      <div className="modal__description">
        <h1>Trykk “Create pin”</h1>
      </div>
    ),
  },
  {
    bilde: [img4],
    beskrivelse: (
      <div className="modal__description">
        <h1>Trykk "Next"</h1>
      </div>
    ),
  },
  {
    bilde: [img5],
    beskrivelse: (
      <div className="modal__description">
        <h1>Microsoft Authenticator del 1</h1>
        <p>
          Last ned Microsoft Authenticator appen på mobilen din og trykk “Next”.
        </p>
      </div>
    ),
  },
  {
    bilde: [img7, img8, img9],
    beskrivelse: (
      <div className="modal__description">
        <h1>Microsoft Authenticator del 2</h1>

        <p>Åpne appen og trykk på + oppe i høyre hjørne.</p>
        <p>Velg “Jobb- eller skolekonto” og “Scan QR-kode”.</p>
        <p>Scan QR-koden fra laptopen med mobilen din.</p>
      </div>
    ),
  },
  {
    bilde: [img10, img11, img12],
    beskrivelse: (
      <div className="modal__description">
        <h1>Microsoft Authenticator del 3</h1>
        <p>Skriv inn nummeret i appen og svar “Ja”</p>
        <p>Trykk “Next”</p>
      </div>
    ),
  },
  {
    bilde: [img28, img29],
    beskrivelse: (
      <div className="modal__description">
        <h1>Microsoft Authenticator del 4</h1>

        <p>Skriv inn “AuthPassord”</p>
        <p>Trykk “Next”</p>
        <p>
          Du trenger ikke å lagre passordet som genereres. Helpdesk vil hjelpe
          deg med å lage nytt passord hvis du skulle havne i en situasjon hvor
          det er nødvendig.
        </p>
        <p>Trykk “Done”</p>
      </div>
    ),
  },
  {
    bilde: [img30],
    beskrivelse: (
      <div className="modal__description">
        <h1>Microsoft Authenticator del 5</h1>
        <p>Trykk “Done”.</p>
      </div>
    ),
  },
  {
    bilde: [img27],
    beskrivelse: (
      <div className="modal__description">
        <h1>Endre PIN</h1>

        <p>Velg “Include letters and symbols”</p>
        <p>
          Opprett et nytt, personlig passord. Dette må inneholde minst 16 tegn
          og kan ikke inneholde æ, ø og å. Vi anbefaler at dette passordet er
          det samme som du ønsker å bruke på Office365-kontoen din, slik at du
          ikke må huske to passord.
        </p>
        <p>Gå videre.</p>
      </div>
    ),
  },
  {
    bilde: [img16],
    beskrivelse: (
      <div className="modal__description">
        <h1>Endre passord</h1>

        <p>Trykk ctrl+alt+del</p>
        <p>Velg “Change a password”</p>
        <p>Trykk på din e-postkonto</p>
        <p>
          Skriv inn <b>skuret12W!</b> under “Old password”
        </p>
        <p>
          Opprett et nytt, personlig passord. Dette må inneholde minst 16 tegn
          og kan ikke inneholde æ, ø og å. Vi anbefaler at dette passordet er
          det samme som det du opprettet i forrige steg.
        </p>
      </div>
    ),
  },
  {
    bilde: [img31],
    beskrivelse: (
      <div className="modal__description">
        <p>
          Steng nettleservinduet og ta deg en fem-minutters pause mens passordet
          ditt blir synkronisert.
        </p>
      </div>
    ),
  },
  {
    bilde: [],
    beskrivelse: (
      <div className="modal__description">
        <h1>Passordoppsettet er fullført!</h1>
        <p>
          Prøv å logge ut og deretter inn igjen med ditt nye passord for å
          kontrollere at det nye passordet ditt funker som det skal.
        </p>
      </div>
    ),
  },
];

const ByttPassord = ({ setIsOpen }) => {
  const [device, choseDevice] = useState();
  const [count, setCount] = useState(0);

  switch (device) {
    case "LENOVO":
      return (
        <div id="byttPassord">
          <div className="scrollview">
            <div id="byttPassordInnhold">
              <BildeBoks images={pcPages[count].bilde} />
              {pcPages[count].beskrivelse}
            </div>
          </div>

          <NavigasjonsKnapper
            limit={pcPages.length}
            current={count}
            backward={() => {
              setCount(count - 1);
            }}
            forward={() => {
              setCount(count + 1);
            }}
            zeroAction={() => {
              choseDevice(null);
            }}
            endAction={() => {
              setIsOpen(false);
            }}
          />
        </div>
      );
    case "MAC":
      return (
        <div id="byttPassord">
          <div className="scrollview">
            <div id="byttPassordInnhold">
              <BildeBoks images={macPages[count].bilde} />
              {macPages[count].beskrivelse}
            </div>
          </div>
          <NavigasjonsKnapper
            limit={macPages.length}
            current={count}
            backward={() => {
              setCount(count - 1);
            }}
            forward={() => {
              setCount(count + 1);
            }}
            zeroAction={() => {
              choseDevice(null);
            }}
            endAction={() => {
              setIsOpen(false);
            }}
          />
        </div>
      );
    default:
      return (
        <div id="byttPassordVelger">
          <h1>Hvilken type laptop har du?</h1>
          <div style={{ display: "flex" }}>
            <button
              className="modal__laptop-brand-button"
              onClick={() => choseDevice("LENOVO")}
            >
              LENOVO
            </button>
            <button
              className="modal__laptop-brand-button"
              onClick={() => choseDevice("MAC")}
            >
              MAC
            </button>
          </div>
        </div>
      );
  }
};

export default ByttPassord;
