import React, { useState } from "react";
import justin1 from "./assets/images/justin1.png";
import justin2 from "./assets/images/justin2.png";
import justin3 from "./assets/images/justin3.png";
import justin4 from "./assets/images/justin4.png";
import justin5 from "./assets/images/justin5.jpg";
import BildeBoks from "./components/BildeBoks";
import NavigasjonsKnapper from "./components/Navigasjonsknapper";

const pages = [
  {
    bilde: [justin1, justin2],
    beskrivelse: (
      <div className="modal__description">
        <h1>Sett opp tilgangsstyring til Skur 39</h1>
        <p>
          Last ned JustIN Mobile appen og følg instruksjonene på skjermen.
          <br />
          <br />
        </p>
      </div>
    ),
  },
  {
    bilde: [justin3],
    beskrivelse: (
      <div className="modal__description">
        <h1>Når du låser deg inn</h1>
        <p>Velg Bekk-kortet</p>
      </div>
    ),
  },
  {
    bilde: [justin4, justin5],
    beskrivelse: (
      <div className="modal__description">
        <h1>Når du låser deg inn</h1>
        <p>Trykk på den grønne nøkkelen for å låse opp døren.</p>
      </div>
    ),
  },
];

const JustIn = ({ setIsOpen }) => {
  const [count, setCount] = useState(0);

  return (
    <div id="byttPassord">
      <div className="scrollview">
        <div id="byttPassordInnhold">
          <BildeBoks images={pages[count].bilde} />
          {pages[count].beskrivelse}
        </div>
      </div>

      <NavigasjonsKnapper
        limit={pages.length}
        current={count}
        backward={() => {
          setCount(count - 1);
        }}
        forward={() => {
          setCount(count + 1);
        }}
        zeroAction={null}
        endAction={() => {
          setIsOpen(false);
        }}
      />
    </div>
  );
};

export default JustIn;
