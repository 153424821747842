import React, { useState } from "react";
import Modal from "./components/Modal";
import ByttPassord from "./ByttPassord";
import JustIn from "./JustIn";
import "./oppsett-pc.less";

const Oppsett = () => {
  const [isByttPassordOpen, setIsByttPassordOpen] = useState(false);
  const [isJustInOpen, setIsJustInOpen] = useState(false);

  if (isByttPassordOpen || isJustInOpen) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "unset";
  }

  return (
    <article id="oppsett">
      <h1>Oppsett av laptop</h1>
      <OppsettSteg steg="1" tittel="Bytte passord">
        <p>
          {" "}
          Følg instruksjonene for bytting av passord: <br />
          <br />
        </p>
        <button
          className="oppsett__open-guide-button"
          onClick={() => setIsByttPassordOpen(true)}
        >
          ÅPNE GUIDE
        </button>
        {isByttPassordOpen && (
          <Modal setIsOpen={setIsByttPassordOpen}>
            <ByttPassord setIsOpen={setIsByttPassordOpen} />
          </Modal>
        )}
      </OppsettSteg>
      <OppsettSteg steg="2" tittel="Bytte SIM-kort">
        <p>
          Nå som du jobber i Bekk får du mobilabonnement dekket. Det er Telia
          som leverer dette. Dersom du trenger å bytte SIM-kort for å få tilgang
          til det nye abonnementet ditt skal du ha fått dette utdelt.
        </p>
        <p>
          Vi har bestilt flytting av abonnement fra tidligere leverandør til
          Telia kl 10.00 i dag , men de kan ikke garantere at alle blir flyttet
          samtidig. <strong>Ikke bytt SIM-kort før du må.</strong> Dette betyr
          at du må ha med deg SIM-kortene, både nytt og gammelt, til du mister
          dekning på det gamle og ser at du har dekning på det nye.
        </p>
      </OppsettSteg>
      <OppsettSteg steg="3" tittel="Sett opp e-post">
        <ul>
          <li>
            Logg inn på Bekks webmail-klient:{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://outlook.office365.com"
            >
              {" "}
              https://outlook.office365.com
            </a>
          </li>
          <li>
            Lag din egen e-postsignatur i henhold til Bekk-standarden (hint:
            send en tom e-post til signatur@bekk.no).
          </li>
          <li>
            Send en e-post til en annen person i rommet for å verifisere at
            signaturen har blitt riktig.
          </li>
          <li>Frivillig: Last ned Outlook-appen på telefonen.</li>
        </ul>
      </OppsettSteg>

      <OppsettSteg steg="4" tittel="Kom i gang med Slack">
        <ul>
          <li>
            Slack er lastet ned på laptopen, men du må laste den ned på mobil.
          </li>
          <li>Logg inn på Slack. Workspacet til Bekk heter bekk.slack.com.</li>
          <li>
            Brukernavnet ditt er fornavn.etternavn@bekk.no. Tips! Hvis du har
            hatt sommerjobb hos oss før kan du bruke samme e-postadresse som du
            hadde da.
          </li>
          {/* <li>
            Last opp et profilbilde. Du kan når som helst bytte det på et senere
            tidspunkt.
          </li>
          */}
          <li>Bli med i slack-kanalen #bootcamp-2024</li>
          <li>
            Bli med i et par spennende slack-kanaler.{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://bekk.slack.com/archives/C028UJTME/p1660648596084269"
            >
              I dette innlegget i #random
            </a>{" "}
            finner du en tråd med masse tips.
          </li>
          <li>
            Skru på varslinger på Slack <b>på mobil</b> for kanalen
            #bootcamp-2024.
          </li>
          <li>Hils på dine nye kollegas ved å si “Hei!” i #general.</li>
        </ul>
      </OppsettSteg>

      <OppsettSteg steg="5" tittel="Sett opp tilgangsstyring til Skur 39">
        <span style={{ marginTop: "1rem" }}>
          <p>
            Skur 39 er alltid låst, for å kunne låse deg inn må du sette opp
            tilgangsstyring med mobilen.
          </p>
          <br />
          <button
            className="oppsett__open-guide-button"
            onClick={() => setIsJustInOpen(true)}
          >
            ÅPNE GUIDE
          </button>
          {isJustInOpen && (
            <Modal setIsOpen={setIsJustInOpen}>
              <JustIn setIsOpen={setIsJustInOpen} />
            </Modal>
          )}
        </span>
      </OppsettSteg>

      <OppsettSteg steg="6" tittel="Praktisk info">
        <ul>
          <li>
            Åpne{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://forside.bekk.no"
            >
              forside.bekk.no.
            </a>{" "}
            Dette er forsiden til Bekks intranett hvor du finner veien videre
            til alle interne applikasjoner som f.eks. timeføring og merkevare.
          </li>
          <li>
            Bytt e-postadresse til din Bekk-adresse under din oppføring i
            ansattlisten. Gå til{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://ansatt.bekk.no/"
            >
              ansatt.bekk.no
            </a>
            , søk opp deg selv og klikk på blyanten til høyre for navnet ditt.
          </li>
          <li>
            Svipp innom CVen og profilen din og sjekk at all informasjon er
            korrekt. Sjekk spesielt kontaktinfo.
          </li>
          <li>
            Les gjennom{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://bekkbok.bekk.no/#/1-meg-og-bekk/50-ny-i-bekk#ny-i-bekk"
            >
              “Ny i Bekk” i Bekk Bok.
            </a>
          </li>
          <li>
            Les{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://bekkbok.bekk.no/#/6-etiske-retningslinjer/68-etiske-retningslinjer"
            >
              Bekks etiske retningslinjer
            </a>{" "}
            og send inn skjemaet som du finner i bunnen på denne siden når du er
            ferdig.
          </li>
        </ul>
      </OppsettSteg>

      <OppsettSteg steg="7" tittel="Tilpass laptopen din etter egne behov">
        <span style={{ marginTop: "1rem" }}>
          <p>
            Alle i Bekk får en laptop fra Bekk. Noen bruker denne ute på
            oppdrag, mens andre får utdelt egen maskin fra kunden på grunn av
            spesielle krav til f.eks. sikkerhet.
          </p>
          <p>
            Ta en titt på hvilke programvarer som er lastet ned på laptopen din.
            Du står fritt til å sette opp laptopen din med programvarene du har
            lyst på. For deg som er utvikler er IntelliJ og VS Code
            forhåndsinnstallert, men du kan selv laste ned andre editorer hvis
            du vil det.
          </p>
          <p>
            Hvis du har mac, er appen Privileges forhåndsinnstallert. Denne
            appen gir deg midlertidig administratortilgang i 30 minutter. Når
            appen er åpen, kan du installere programmer og gjøre endringer som
            krever administratortilgang.
          </p>
          <p>
            Ellers er det bare å gjøre laptopen personlig med dine egne
            favorittinnstillinger for fargetema, tastatursnarveier osv.
          </p>
          <p>
            For deg som aldri har brukt Mac før, er det bare å gjøre seg kjent,
            og lære seg en haug med nye shortcuts.
          </p>
        </span>
      </OppsettSteg>

      <div className="numbered-list">
        <p>Ferdig allerede?</p>
        <span style={{ marginBottom: "1rem" }}>
          <p>
            Bruk tiden til å sosialisere med dine nye kollegas! Du kan også
            svippe en tur innom LinkedIn og oppdatere informasjonen din der.
          </p>
          <p>
            Hvis du er nysgjerrig på hva livet i Bekk har å by på kan du også
            besøke{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://spirit.bekk.no"
            >
              spirit.bekk.no
            </a>{" "}
            for å se om det er noen interessegrupper du kunne tenke deg å bli
            med i. Du kan også besøke{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://skjer.bekk.no"
            >
              skjer.bekk.no
            </a>{" "}
            for å se hvilke sosiale arrangementer som er planlagt sålangt i
            høst!
          </p>
        </span>
      </div>
    </article>
  );
};

const OppsettSteg = (props) => (
  <div className="numbered-list">
    <h2>
      Steg {props.steg}: {props.tittel}
    </h2>
    <span style={{ marginBottom: "1rem" }}></span>
    {props.children}
  </div>
);

export default Oppsett;
